.no_jira_icon {
    display: flex;
    width: 100%;
    height: 100%;
    place-items: center;
    justify-content: center;
    position: relative; /* Ensure the :after element is positioned relative to .no_jira_icon */
    overflow: hidden; /* Ensure content doesn't overflow */

    &:hover::after {
        content: "No Jira Ticket"; /* Set the content to display on hover */
        color: white; /* Set text color */
        position: absolute; /* Position the text absolutely within the .no_jira_icon */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        transform: translate(-50%, -50%); /* Center text */
        background-color: #2d5ca9;
        padding: 5px; /* Optional: add some padding */
        border-radius: 3px; /* Optional: add rounded corners */
        font-size: x-small;

        /* Animation properties */
        opacity: 0; /* Start with the text hidden */
        animation: fadeIn 0.3s forwards; /* Apply fade-in animation on hover */
    }

    &:not(:hover)::after {
        animation: fadeOut 0.3s forwards; /* Apply fade-out animation when not hovering */
    }
}

/* Keyframes for fade-in */
@keyframes fadeIn {
    from {
        opacity: 0; /* Start hidden */
    }
    to {
        opacity: 1; /* End fully visible */
    }
}

/* Keyframes for fade-out */
@keyframes fadeOut {
    from {
        opacity: 1; /* Start fully visible */
    }
    to {
        opacity: 0; /* End hidden */
    }
}
