.navbar {
    background-color: white;
    height: 80px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    border-top: .5px solid #D6D8DA;
    border-bottom: .5px solid #D6D8DA;
    padding: 0 20px;
}

.navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.container {
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
}

.navbar-logo-box {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: white;
    max-width: 200px;
    box-shadow: 0 0 1px rgba(0,0,0,.1);
}

.navbar-logo {
    margin-right: 20px; /* Add some space between the logo and the button */
}

img {
    max-width: 200px; /* Ensure the logo has a consistent size */
    max-height: 80px; /* Adjust the height to maintain aspect ratio */
    display: block;
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    margin-left: auto; /* Ensure menu items align to the right */
    font-weight: 700;
    font-style: normal;
}

.nav-item {
    margin-left: 20px;
}

.navbar-item {
    height: 80px;
    border-bottom: 2px solid transparent;
    font-family: "Proxima Nova";
}

.nav-links {
    color: #1865f2;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.navbar-item:hover {
    border-bottom: 3px solid #1865f2;
}
