.columnOrder-container {
    display: flex;
    gap: 0px;
    position: absolute;
    top: 36px;
    left: 4px;
    background-color: white;
    z-index: 90;
    padding: 10px;
    border: 1px solid #ddd;
    flex-direction: column;
    width: 200px;
    border-radius: 5px;
    cursor: default;
}

.columnOrder-item {
    padding: 8px;
    margin-bottom: 4px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    width: 90%;
    margin: 5%;
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    cursor: move;
    text-align: center;
    display: flex;
}

.columnOrder-dragging {
    position: fixed;
    pointer-events: none;
    opacity: 0.8;
    background-color: #e0e0e0;
    border: 1px dashed #ccc;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}
