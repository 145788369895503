.title {
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  margin-bottom: 5px;
  margin-left: 0px;
  
}

#modal_container {
width:10px; 
height:100%;}



#modal-modal-title {
  background-color: #2D5CA9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
font-size: 2rem;
font-weight: 500;
color:white;
border-top-right-radius: 25px;
border-top-left-radius: 25px;


}


#modal-modal-status-pass {
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
font-size: 2rem;
font-weight: 500;
color:white;
}

#modal-modal-status-fail {
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
font-size: 2rem;
font-weight: 500;
color:white;
}

#modal-list-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
font-size: 1.5rem;
font-weight: 500;
color:white;
}
.modal-style {
color:#2D5CA9;
font-family: 'Times New Roman';
font-weight: 1000px;
}

.cancel-style {
  border:none;
  background-color:transparent;
  outline:none;
}

#modal-list-li {
  list-style: none;
  padding: 0;
  color:white;
  display: flex;
  justify-content: space-between;
  
}
#modal-list-span {
flex-grow: 1;
}

#modal-list-li span:first-child {
font-weight: bold;
}
#modal-list-li span:last-child {

text-align: right;
}

#modal-modal-description {

  padding: 10px;
}

#modal-list-container {
  background-color: lightblue;
  padding: 20px;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;

}