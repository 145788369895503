/* Styles for the switch label */
.showCopyIcon-switch {
    position: relative;
    height: .8rem;
    width: 2rem;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 9999px;
    background-color: rgba(100, 116, 139, 0.377);
    transition: all .3s ease;
}

/* Hide the input checkbox */
.showCopyIcon-input {
    display: none;
}

/* Styles for the slider */
.showCopyIcon-slider {
    position: absolute;
    content: "";
    left: calc(1.5rem - 1.6rem);
    top: calc(1.5rem - 1.6rem);
    display: block;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    border: 1px solid rgba(100, 116, 139, 0.527);
    border-radius: 9999px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 3px 10px rgba(100, 116, 139, 0.327);
    transition: all .3s ease;
}

/* Styles for the slider when the input is checked */
.showCopyIcon-input:checked {
    transform: translateX(100%);
    border-color: #2d5ca9;
    background-color: #2d5ca9;
}

/* Styles for the slider when the input is not checked */
.showCopyIcon-input:not(:checked) {
    transform: translateX(0);
    border-color: rgba(100, 116, 139, 0.527);
    background-color: rgba(100, 116, 139, 0.377);
}

/* Styles for the text label */
.showCopyIcon-text-label {
    cursor: pointer;
    margin-left: 4px;
}

/* Styles for the font size slider container */
.fontSizeSlider-div {
    display: flex;
    justify-content: center;
    align-items: center;
    place-content: center;
    color: #2d5ca9;
    gap: 12px;
    margin-left: 8px;
}

