.modal {
    width: 600px;
    display: none;
    position: fixed;
    top : 90%;
    left:50% ;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .modal_content {
    background-color: white;
    position: absolute;
  }