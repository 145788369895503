:root {
    --primary : #fff;
}

.btn {
    padding: 8px 20px ;
    border-radius :4px;
    outline :none ;
    cursor:pointer ;
    white-space: nowrap;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);

}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding:8px 20px;
    border: 1px solid var(--primary);  
    transition: all 0.3 ease-out;
}

.btn--medium {
    padding :8px 12px;
    font-size: 18px;
}

.btn--large {
    padding: 12px 26px;
    font-size:20px;
}

.primary {
    background-color: #242424;
    color : #fff ;
    border : none ;
}

.blue {
    background-color: blue;
    color : #fff ;
    border : none ;
}


