.loading::after {
	display: inline-block;
	animation: dotty 2s steps(1, end) infinite;
	content: "   "; /* Ensure it starts with 3 spaces */
	width: 3ch; /* Set width to 3 characters */
	font-family: monospace; /* Ensure consistent character width */
}

@keyframes dotty {
	0% {
		content: "   "; /* 3 spaces */
	}
	25% {
		content: ".  "; /* 1 dot + 2 spaces */
	}
	50% {
		content: ".. "; /* 2 dots + 1 space */
	}
	75% {
		content: "..."; /* 3 dots */
	}
	100% {
		content: "   "; /* 3 spaces */
	}
}
