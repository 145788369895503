.hold-button-container {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    width: 100%;
    height: 100%;
    text-overflow: hidden;
    overflow: hidden;
    gap: 4px;
    font-size: inherit;
    position: relative;
}

.hold-button-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    font-size: inherit;
}

.hold-button {
    width: 100%;
    height: 100%;
    display: flex;
    color: inherit;
    font-size: inherit;
    justify-content: flex-start;
    padding: 0;
    position: relative;
}

.hold-button-text {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}
