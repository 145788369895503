.dashboard-container{
    display: flex;
    margin-top: 5px;
}

.dashboard-main {
    flex:5;

}

.modalIdle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    backdrop-filter: blur(5px); /* apply blur filter */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    width: 50%;
  }
  
  .modal h2 {
    margin-top: 0;
  }
  
  .modal p {
    margin-bottom: 20px;
  }
  
  .modal button {
    width: 50%;
    padding: 10px 20px;
    /* margin-right: 10px; */
    border: none;
    border-radius: 4px;
  
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .modal button:hover {
    background-color: #45a049;
  }
  
  .modal button:last-child {
    margin-right: 0;
  }