.sidebar {
  height: calc(100vh - 50px);
  background-color: whte;
  position: sticky;
  top: 50px;
}
.sidebarWrapper {
  position: relative;
  width: 140px;
  height: 100%;
  transition: width 0.5s ease; 
}

.sidebarWrapper.collapsed {
  width: 40px;
}

.sidebarMenu {
  margin-bottom: 10px;
  margin-right: 80px;

}

.sidebarTitle {
  font-size: 15px;
  color: #1865f2;
  font-weight: 800;



}

.sidebarList {
  list-style: none;
  padding: 5px;

  
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  color: #1865f2;
  display: flex;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  

}


.sidebarListItem:hover {
  background-color: #F2F9FA;
  width: 150%;
}


.sidebarListItem:visited {
  color: #1865f2; /* Or any other color you want */
}

.sidebarIcon{

    margin-right: 5px;
    font-size: 20px !important;
}

.a {
  text-decoration: none;
}